<template>
  <div class="project-details">
    <div class="flex j-btw">
      <div class="flex column row-1">
        <div class="title pb-55">{{ data.title }}</div>
        <div class="text" v-html="data.teaser"></div>
        <div @click="openVideo"
             @mouseenter="store.getComponentCursor($event, 'play')"
             @mouseleave="store.getComponentCursor($event, null)"
             class="video pointer flex">
          <!--           <video  alt="video" width="170" height="170">-->
          <!--            <source :src="data.video.videoFile.url" :type="data.video.videoFile.type">-->
          <!--          </video>-->
          <img src="@/assets/imgs/videoImg.png" alt="video" width="170" height="170"/>
          <div class="flex column j-center">
            <p class="pointer">{{ data.video.title }}</p>
            <div class="label">{{ data.video.teaser }}</div>
          </div>
        </div>
      </div>
      <div class="flex column row-2">
        <div class="flex column">
          <p class="f-36">{{ data.title2 }}</p>
          <p class="mb-21">{{ data.teaser2 }}</p>
        </div>
        <div class="flex column project-details__info text" v-html="data.content_alt"></div>
      </div>
    </div>
    <Teleport to="body">
      <transition name="fade" :duration="1500">
        <div v-if="showVideo"
             @click="showVideo=false"
             @mouseenter="store.getComponentCursor($event, 'zoom-cancel')"
             @mouseleave="store.getComponentCursor($event, null)"
             class="video-container">
          <video id="video" controls alt="video">
            <source :src="data.videoUrl" :type="data.videoType">
          </video>
          <div @click="showVideo=false"
               class="close-container">
            <svg width="31"
                 height="31"
                 viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="1.86731" width="1.7657" height="41.1998" transform="rotate(-45 0 1.86731)" fill="black"/>
              <rect x="29.1326" y="0.618774" width="1.7657" height="41.1998" transform="rotate(45 29.1326 0.618774)"
                    fill="black"/>
            </svg>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import {useMenuStore} from "@/store/pinia/menu";

let store = useMenuStore()
const props = defineProps({
  contentData: {
    type: Object,
  }
})
let showVideo = ref(false)
const data = computed(() => {
  let item = props.contentData.data?.list?.[0]
  return {
    ...item,
    videoUrl: item.video?.videoFile?.url,
    videoType: item.video?.videoFile?.mime_type,
  }
})
const toggleVideo = (play = false) => {
  let vid = document.getElementById("video");

  play ? vid.play() : vid.stop()
}
const openVideo = () => {
  showVideo.value = true
  setTimeout(() => {
    toggleVideo(true)
  }, 100)
}
</script>

<style lang="scss" scoped>
.project-details {
  padding: 0 100px;
  @include mq(desktop-sm) {
    padding: 0 80px;
  }
  @include mq(tablet) {
    padding: 0 16px;
  }

  > .flex {
    @include mq(mobile) {
      flex-direction: column;
    }
  }

  .f-36 {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 15px;
    @include mq(tablet-sm) {
      font-size: 20px;
      line-height: 24px;
    }
    @include mq(mobile) {
      font-size: 28px;
      line-height: 33px;
      margin-top: 30px;
    }
  }

  .mb-130 {
    margin-bottom: 130px;
    @include mq(tablet-sm) {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 55px;
    }
  }

  .title {
    font-size: 64px;
    line-height: 76px;
    @include mq(tablet) {
      font-size: 40px;
      line-height: 48px;
    }
    @include mq(tablet-sm) {
      font-size: 24px;
      line-height: 29px;
    }
    @include mq(mobile) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  .flex {
    .row-1 {
      max-width: 50%;
      @include mq(mobile) {
        max-width: unset;
      }
    }
  }

  .text {
    p {
      margin-bottom: 71px;
      font-size: 34px;
      line-height: 41px;

      @include mq(tablet) {
        font-size: 28px;
        line-height: 34px;
      }
      @include mq(tablet-sm) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
      }
      @include mq(mobile) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .row {
    p {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .video {
    margin-top: 55px;

    img {
      margin-right: 55px;
      @include mq(tablet-sm) {
        width: 99px;
        height: 99px;
      }
      @include mq(tablet-sm) {
        margin-right: 15px;
      }
      @include mq(mobile) {
        width: 120px;
        height: 120px;
      }
    }

    p {
      font-size: 34px;
      line-height: 41px;
      text-decoration: underline;
      margin-bottom: 16px;
      @include mq(tablet-sm) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .label {
      @include mq(tablet-sm) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  &__map {
    position: relative;

    .vue-map {
      width: 100%;
      height: 1000px;
    }

    .interests {
      width: 400px;
      background-color: $white;
      position: absolute;
      z-index: 10;

      &__dropdown {

        &-item {
          padding: 15px 34px;

          p {
            position: relative;
            width: fit-content;
            @include hoverLineAnimation;
            font-size: 24px;
            line-height: 29px;
          }

          &:first-child {
            padding-top: 30px;
          }

          &:last-child {
            padding-bottom: 30px;
          }
        }
      }

      &__title {
        padding: 26px 34px;
        height: 81px;
        font-size: 24px;
        line-height: 29px;
      }

      svg {
        @include easeOut(0.5s, all);
      }

      .active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__info {
    max-width: 480px;
    @include mq(mobile) {
      max-width: unset;
    }

    &.text {
      p {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 30px;
        @include mq(tablet-sm) {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 25px;
        }
        @include mq(mobile) {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s cubic-bezier(.33, 1, .68, 1);
}

.fade-leave-active {
  transition-delay: 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.fade-enter-active video,
.fade-leave-active video {
  transition: 0.5s cubic-bezier(.33, 1, .68, 1);
}

.fade-enter-active video {
  transition-delay: 0.5s;
}

.fade-enter-from video,
.fade-leave-to video {
  opacity: 0;
}


.video-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: #242424;

  .close-container {
    position: absolute;
    width: 52px;
    height: 52px;
    background: #D9D9D9;
    align-items: center;
    justify-content: center;
    display: none;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    cursor: pointer;
    @media (max-width: 1023px) {
      display: flex;
    }

    &:hover {
      opacity: .7;
    }
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}
</style>
